/* eslint-disable camelcase */

export const defaultConfigKeys = {
    checkout_button_disabled: false,
    grocery_onboarding_url: '',
    grocery_onboarding_enabled: false,
    complete_org_prompt_enabled: false,
    lite_plus_enrollment_enabled: false,
    default_promotion_landing_id: '',
    enrollment_transition_time: 1000,
    org_type_contentful_mapping: '',
    org_enrollment_contentful_heading_map: '',
    auth_web_homepage_entry_id: '',
    unauth_web_homepage_entry_id: '',
    web_component_mappings: '',
    support_url: '',
    brands_on_bonus_hero_id: '',
    favorites_hero_id: '',
    featured_page_hero_id: '',
    family_enrollment_contentful_heading_map: '',
    unauth_brands_page_hero_id: '',
    auth_brands_page_hero_id: '',
    auth_giftcards_page_hero_id: '',
    auth_shoponline_page_hero_id: '',
    fincen: '',
    allow_gifting_egift: false,
    enrollment_code_reset_email: '',
    disable_leave_organization: false,
    learn_more_about_cards_url: '',
    enable_family_reporting: false,
    enable_checkout_button: true,
    enable_ship_to_coordinator: true,
    enable_custom_card_names: false,
    enable_cart_brand_card_tiles: false,
    cart_brand_card_tiles: '',
    enrollment_v2_enabled: false,
    org_enrollment_version: 1,
    wallet_polling_max_time: 300,
    wallet_refresh_interval: 22,
    wallet_elapsed_time_polling_trigger: 180,
    enable_Zopim_chat: false,
    enable_edit_in_cart_quantity: false,
    enable_edit_in_cart_quantity_web: false,
    allow_gifting_egift_native: false,
    enable_mobile_push_notifications: false,
    enable_categories_infinite_scrolling_native: false,
    enable_invite_friends_family: false,
    enable_coordinator_enrollment_code_resources_web: false,
    max_cart_line_items: 125,
    scrolling_categories_dedupe_list: '',
    rokt_categories_dedupe_list: '',
    thirdparty_viewer_flow_ids: '',
    affiliate_error_message_web: '',
    affiliate_error_message_app: '',
    search_placeholder_text: '',
    add_favorites_category_tabs: '',
    scrolling_category_order: '',
};
