import { createSlice } from 'common/modules/create-slice';
import { mockResponse, mockResponseByMerchantId } from './mockResponse';
import { createSuccessfulResponse } from 'common/api/utils/createAPIResponse';
import { MerchantDto, PaginatedMerchantsDto } from './schema';
import { FeaturesState } from 'common/features/featuresReducer';
import { Dispatch } from 'redux';
import { delay } from 'common/utils';

export interface LocalDiningState {
    cardsLinked: []; // List of linked credit cards
    manuallyTypedLocation: string; // manually entered location (City, State or Zip Code)
    userLocation: string;
    // value used for initial SEARCH upon landing on the screen.
    // This value is not meant to be rendered.
    defaultLocation: string;
    locationGPS: { latitude: number; longitude: number } | null; // GPS data retrieved from RN Location Api
    useCurrentLocation: boolean;
    searchResults?: PaginatedMerchantsDto;
    searching: boolean;
    hasRequestedLocationTracker: boolean;
}

const initialState: LocalDiningState = {
    cardsLinked: [],
    manuallyTypedLocation: '',
    userLocation: '',
    defaultLocation: '',
    locationGPS: null,
    useCurrentLocation: false,
    searchResults: undefined,
    searching: true,
    hasRequestedLocationTracker: false,
};

const localDiningSlice = createSlice(initialState, 'CARD_LINKED_OFFERS');
export const updateLocalDiningSlice = localDiningSlice.update;

export const resetLocalDining = () => localDiningSlice.update(initialState);

export const setLocation = localDiningSlice.configureAction(
    'SET_MANUALLY_TYPED_LOCATION',
    (location: string) => (state) => ({
        ...state,
        manuallyTypedLocation: location,
    })
);

export const setLocationGPS = localDiningSlice.configureAction(
    'SET_LOCATION_GPS',
    (locationGPS: { latitude: number; longitude: number }) => (state) => ({
        ...state,
        locationGPS,
    })
);

export const setUseCurrentLocation = localDiningSlice.configureAction(
    'SET_USE_CURRENT_LOCATION',
    (useCurrentLocation: boolean) => (state) => ({
        ...state,
        useCurrentLocation,
    })
);

type MerchantsRequestBody = {
    longitude?: number;
    latitude?: number;
    cityState?: string;
    zip?: string;
};

// TODO: Call the merchants API - merchantDto
export const fakeMerchantById = async (id: number) => {
    const response =
        mockResponseByMerchantId.find((m) => m.id === id) ?? mockResponseByMerchantId[0];
    return createSuccessfulResponse<MerchantDto>(response);
};

// TODO: Call the merchants API
const searchMerchants = async (requestBody: MerchantsRequestBody) => {
    // api access for endpoint
    // S2V5OiBDek1WZEFRTmtiaGkwaFJBVklqWXE3NEMxR0QzS0FrVQ==
    // U2VjcmV0OiBEN1YwMzRPQWcxS0dReDVV
    let queryParams = '';
    if (requestBody.cityState) {
        queryParams = `cityState=${encodeURIComponent(requestBody.cityState)}`;
    } else if (requestBody.zip) {
        queryParams = `zip=${requestBody.zip}`;
    } else if (requestBody.latitude && requestBody.longitude) {
        queryParams = `latitude=${requestBody.latitude}&longitude=${requestBody.longitude}`;
    }
    // consolelog('QueryParams: ', queryParams);
    // const result = await fetch(
    // 	`https://staging-api.rewardsnetwork.com/v2/merchants/search?${param}=${value}`,
    // 	{
    // 	    headers: {
    // 		Key: 'CzMVdAQNkbhi0hRAVIjYq74C1GD3KAkU',
    // 		Secret: 'D7V034OAg1KGQx5U'
    // 	    }
    // 	}
    // );
    // if (result) {
    // 	let r = result.json();
    // 	consolelog("RESULT", JSON.stringify(r,null,2));
    // }
    await delay(1500);
    return mockResponse;
};

export const searchMerchantsThunk =
    (request: MerchantsRequestBody, saveLocationAsUserLocation?: boolean) =>
    async (dispatch: Dispatch, getState: () => FeaturesState) => {
        try {
            // consolelog('BEING CALLED', JSON.stringify(request, null, 2));
            let updatePayload: Partial<LocalDiningState> = {
                searching: true,
            };

            if (saveLocationAsUserLocation === undefined) {
                updatePayload['manuallyTypedLocation'] = request.cityState || request.zip;
                updatePayload['useCurrentLocation'] = false;
            }

            dispatch(localDiningSlice.update(updatePayload));
            const result = await searchMerchants(request);
            if (result) {
                updatePayload = {
                    searchResults: result,
                    searching: false,
                };

                if (saveLocationAsUserLocation) {
                    updatePayload['userLocation'] = result.searchArea;
                }

                dispatch(localDiningSlice.update(updatePayload));
            } else {
                localDiningSlice.update({
                    manuallyTypedLocation: request.cityState || request.zip || '',
                    searchResults: undefined,
                    searching: false,
                });
            }
        } catch (e) {
            localDiningSlice.update({ searching: true });
        }
    };

// selectors
export const selectUserLocationGPS = (state: FeaturesState) => state.store.localDining.locationGPS;
export const selectManuallyTypedLocation = (state: FeaturesState) =>
    state.store.localDining.manuallyTypedLocation;
export const selectUserLocation = (state: FeaturesState) => state.store.localDining.userLocation;
export const selectUseCurrentLocation = (state: FeaturesState) =>
    state.store.localDining.useCurrentLocation;
export const selectHasRequestedLocationTracker = (state: FeaturesState) =>
    state.store.localDining.hasRequestedLocationTracker;

export const localDiningReducer = localDiningSlice.reducer;
