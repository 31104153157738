import { getCrmFeatureFlags } from 'common/api/crm/feature-flags';
import { FeaturesState } from 'common/features/featuresReducer';
import { createSlice } from 'common/modules/create-slice';
import { UserAuthState } from '../account/duck';
import { Dispatch } from 'redux';

export enum FeatureFlagTypes {
    allow_buynow_mobile = 'buy_now',
    allow_bulk_gifting = 'allow_bulk_gifting',
    horizontal_scrolling = 'horizontal_scrolling',
    coordinator_toggles = 'coordinator_toggles',
    enable_invite_friends_family = 'enable_invite_friends_family',
    enable_unified_coordinator_dashboard = 'enable_unified_coordinator_dashboard',
    enable_bulk_egifting = 'enable_bulk_egifting',
    enable_coordinator_vacation_mode = 'enable_coordinator_vacation_mode',
    enable_coordinator_checklist = 'enable_coordinator_checklist',
    enable_iterable = 'enable_iterable',
    enable_constructor_search_web = 'enable_constructor_search_web',
    enable_constructor_browse_web = 'enable_constructor_browse_web',
    enable_constructor_search_app = 'enable_constructor_search_app',
    enable_constructor_browse_app = 'enable_constructor_browse_app',
    enable_debit_app = 'enable_debit_app',
    enable_debit_web = 'enable_debit_web',
    enable_payment_banner_app = 'enable_payment_banner_app',
    enable_buy_now_payment_app = 'enable_buy_now_payment_app',
    enable_remote_activation_app = 'enable_remote_activation_app',
    enable_rokt_app = 'enable_rokt_app',
    enable_add_money_now_payment_app = 'enable_add_money_now_payment_app',
    enable_rokt_web = 'enable_rokt_web',
    enable_buy_now_web = 'enable_buy_now_web',
    enable_amex_egift_app = 'enable_amex_egift_app',
    enable_amex_egift_web = 'enable_amex_egift_web',
    enable_constructor_browse_with_affiliate_app = 'enable_constructor_browse_with_affiliate_app',
    enable_constructor_search_with_affiliate_app = 'enable_constructor_search_with_affiliate_app',
    enable_show_affiliate_app = 'enable_show_affiliate_app',
    enable_show_affiliate_web = 'enable_show_affiliate_web',
    enable_flipgive_app = 'enable_flipgive_app',
    enable_constructor_browse_with_affiliate_web = 'enable_constructor_browse_with_affiliate_web',
    enable_constructor_search_with_affiliate_web = 'enable_constructor_search_with_affiliate_web',
    enable_flipgive_web = 'enable_flipgive_web',
    enable_affiliate_double_dip_banner_app = 'enable_affiliate_double_dip_banner_app',
    enable_appsflyer_event_logging = 'enable_appsflyer_event_logging',
    enable_invite_friends_family_from_header = 'enable_invite_friends_family_from_header',
    enable_mobile_inbox_app = 'enable_mobile_inbox_app',
    enable_mobile_inbox_error_app = 'enable_mobile_inbox_error_app',
    enable_add_favorites_screen_app = 'enable_add_favorites_screen_app',
    enable_affiliate_confirmation_app = 'enable_affiliate_confirmation_app',
    enable_bank_copy_app = 'enable_bank_copy_app',
    enable_affiliate_confirmation_web = 'enable_affiliate_confirmation_web',
    enable_card_linked_offers_app = 'enable_card_linked_offers_app',
}

export const WIP_FEATURE_FLAGS: FeatureFlagTypes[] = [];

export interface FeatureFlag {
    [key: string]: boolean;
}

export interface ExperimentalFeatureFlagsState {
    flags: FeatureFlag;
    loading: boolean;
    lastFetch: number;
    loadedCount: number;
    dispatchedKeys: { [key: string]: boolean };
    userIsLoggedIn: boolean;
    userLoginStatusUpdated: boolean;
}

const initialExperimentalFeatureFlagsState: ExperimentalFeatureFlagsState = {
    flags: {},
    loading: false,
    lastFetch: 0,
    loadedCount: 0,
    dispatchedKeys: {},
    userIsLoggedIn: false,
    userLoginStatusUpdated: false,
};

export const fetchFeatureFlags = async (dispatch: any, state: FeaturesState) => {
    if (state?.store?.experimentalFeatureFlags?.loading) {
        return;
    }

    // Avoid fetching too often
    const lastFetch = state?.store?.experimentalFeatureFlags?.lastFetch || 0;
    if (Date.now() - lastFetch < 1000 * 60 * 10) {
        return;
    }

    dispatch(update({ loading: true }));

    try {
        const result = await getCrmFeatureFlags();
        if (result.response?.ok) {
            const flags = (result?.data?.featureFlags || []).reduce(
                (acc, { key, enabled }) => ({ ...acc, [key]: enabled }),
                {}
            );
            dispatch(
                update({
                    flags,
                    loading: false,
                    lastFetch: Date.now(),
                    loadedCount: state.store.experimentalFeatureFlags.loadedCount + 1,
                })
            );
        } else {
            // Handle failure: e.g., setting loading to false, logging, etc.
            dispatch(update({ loading: false }));
        }
    } catch (error) {
        console.error(error);
        dispatch(update({ loading: false }));
    }
};

export const fetchFeatureFlagsV2 =
    () => async (dispatch: Dispatch, getState: () => FeaturesState) => {
        const state = getState();
        const isAuthenticated = state.store.account.userAuthState === UserAuthState.AUTHENTICATED;

        if (state?.store?.experimentalFeatureFlags?.loading || !isAuthenticated) {
            return;
        }

        // Avoid fetching too often
        const lastFetch = state?.store?.experimentalFeatureFlags?.lastFetch || 0;
        if (Date.now() - lastFetch < 1000 * 60 * 10) {
            return;
        }

        dispatch(update({ loading: true }));

        try {
            const result = await getCrmFeatureFlags();
            if (result.response?.ok) {
                const flags = (result?.data?.featureFlags || []).reduce(
                    (acc, { key, enabled }) => ({ ...acc, [key]: enabled }),
                    {}
                );
                dispatch(
                    update({
                        flags,
                        loading: false,
                        lastFetch: Date.now(),
                        loadedCount: state.store.experimentalFeatureFlags.loadedCount + 1,
                    })
                );
            } else {
                // Handle failure: e.g., setting loading to false, logging, etc.
                dispatch(update({ loading: false }));
            }
        } catch (error) {
            console.error(error);
            dispatch(update({ loading: false }));
        }
    };

const { update, reducer } = createSlice(
    initialExperimentalFeatureFlagsState,
    'EXPERIMENTAL_FEATURE_FLAGS'
);

export const experimentalFeatureFlagsReducer = reducer;

export const selectIsEnabled = (state: FeaturesState, key: string) =>
    state.store?.experimentalFeatureFlags.flags[key] ?? false;

export const selectExperimentalFlags = (state: FeaturesState) =>
    state.store?.experimentalFeatureFlags?.flags || {};

export const selectIsIterableEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[FeatureFlagTypes.enable_iterable];
    }
};

export const selectIsConstructorBrowseEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_constructor_browse_with_affiliate_app
        ];
    }
};

export const selectIsConstructorSearchEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_constructor_search_with_affiliate_app
        ];
    }
};

export const selectAffiliateEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_show_affiliate_app
        ];
    }
};

export const selectIsFlipGiveEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[FeatureFlagTypes.enable_flipgive_app];
    }
};

export const selectExperimetnalFlagsLoading = (state: FeaturesState) =>
    state.store?.experimentalFeatureFlags?.loading;

export const selectIsConstructorBrowseEnabledWeb = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_constructor_browse_with_affiliate_web
        ];
    }
};

export const selectIsConstructorSearchEnabledWeb = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_constructor_search_with_affiliate_web
        ];
    }
};

export const selectAffiliateEnabledWeb = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_show_affiliate_web
        ];
    }
};

export const selectIsFlipgiveEnabledWeb = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[FeatureFlagTypes.enable_flipgive_web];
    }
};

export const selectIsAffiliateDoubleDipBannerEnabled = (
    state: FeaturesState
): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_affiliate_double_dip_banner_app
        ];
    }
};

export const selectIsBulkEgiftingEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[FeatureFlagTypes.enable_bulk_egifting];
    }
};

export const selectIsMobileInboxAppEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_mobile_inbox_app
        ];
    }
};

export const selectIsMobileInboxErrorAppEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_mobile_inbox_error_app
        ];
    }
};

export const selectIsAddFavoritesScreenEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_add_favorites_screen_app
        ];
    }
};

export const selectIsAffiliateConfirmationAppEnabled = (
    state: FeaturesState
): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_affiliate_confirmation_app
        ];
    }
};

export const selectIsBankCopyAppEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[FeatureFlagTypes.enable_bank_copy_app];
    }
};

export const selectIsAffiliateConfirmationWebEnabled = (
    state: FeaturesState
): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_affiliate_confirmation_web
        ];
    }
};

export const selectIsP2PEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_invite_friends_family
        ];
    }
};

export const selectIsCardLinkedOffersAppEnabled = (state: FeaturesState): boolean | undefined => {
    if (state.store?.experimentalFeatureFlags?.flags) {
        return state.store?.experimentalFeatureFlags?.flags[
            FeatureFlagTypes.enable_card_linked_offers_app
        ];
    }
};

export const selectHasFetchedFeatureFlags = (state: FeaturesState) => {
    return state.store.experimentalFeatureFlags.lastFetch > 0;
};
