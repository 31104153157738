import { PaginatedMerchantsDto, MerchantDto } from './schema';

export const mockResponse: PaginatedMerchantsDto = {
    totalPages: 1,
    currentPage: 1,
    totalResultSize: 10,
    searchArea: 'Chicago, IL',
    merchants: [
        {
            id: 1,
            rating: {
                overallRating: 4.5,
                numberOfRatings: 65,
                numberOfReviews: 20,
            },
            benefits: [
                {
                    value: 5,
                    dayOfWeek: 'Mon',
                    monthDay: '16/07/2023',
                },
            ],
            type: 'restaurant',
            imageBaseUrl: 'https://cdn.pixabay.com/photo',
            logoImage: '/2023/07/07/17/47/sushi-8113165_1280.jpg',
            photos: [
                '/2020/03/22/08/43/sushi-4956246_960_720.jpg',
                '/2021/01/01/15/31/sushi-balls-5878892_960_720.jpg',
            ],
            menu: {
                menuImages: ['2015/01/09/15/34/chunks-594496_1280.jpg'],
                externalUrl: 'http://www.bestrestaurant1.com/menu',
            },
            name: 'Venue 2106',
            cuisines: ['American'],
            priceRange: 2,
            location: {
                neighborhood: 'Downtown Chicago',
                latitude: 41.8781,
                longitude: -87.6298,
                address: {
                    address1: '123 Main St',
                    city: 'Chicago',
                },
            },
            highlight: 'Family-friendly dining experience',
            acceptedCards: ['Visa', 'MasterCard', 'Discover'],
            hasDelivery: true,
            hasTakeOut: true,
        },
        {
            id: 2,
            rating: {
                overallRating: 4.8,
                numberOfRatings: 120,
                numberOfReviews: 40,
            },
            benefits: [
                {
                    value: 10,
                    dayOfWeek: 'Wed',
                    monthDay: '18/07/2023',
                },
            ],
            type: 'restaurant',
            imageBaseUrl: 'https://cdn.pixabay.com/photo',
            logoImage: '/2017/06/29/20/09/mexican-2456038_1280.jpg',
            photos: [
                '/2023/05/30/14/49/corn-8028831_1280.jpg',
                '/2015/11/02/20/27/taco-1018962_1280.jpg',
            ],
            menu: {
                menuImages: ['/2017/07/16/11/57/fried-2509089_1280.jpg'],
                externalUrl: 'http://www.blanquitasmexican.com/menu',
            },
            name: "Blanquita's Mexican Restaurant",
            cuisines: ['Mexican'],
            priceRange: 1,
            location: {
                neighborhood: 'Wicker Park',
                latitude: 42.954463970814075,
                longitude: -85.6819404857047,
                address: {
                    address1: '456 Market Ave',
                    city: 'Chicago',
                },
            },
            highlight: 'Authentic Mexican Cuisine',
            acceptedCards: ['Visa', 'MasterCard', 'American Express'],
            hasDelivery: false,
            hasTakeOut: true,
        },
        {
            id: 4,
            rating: {
                overallRating: 4.6,
                numberOfRatings: 80,
                numberOfReviews: 25,
            },
            benefits: [
                {
                    value: 6,
                    dayOfWeek: 'Thu',
                    monthDay: '22/07/2023',
                },
            ],
            type: 'restaurant',
            imageBaseUrl: 'https://cdn.pixabay.com/photo',
            logoImage: '/2017/12/09/08/18/pizza-3007395_1280.jpg',
            photos: [
                '/2017/12/09/08/18/pizza-3007395_960_720.jpg',
                '/2021/07/19/16/04/pizza-6478478_960_720.jpg',
            ],
            menu: {
                menuImages: ['/2022/03/04/00/47/wine-7046276_960_720.jpg'],
                externalUrl: 'http://www.pizzaplace.com/menu',
            },
            name: 'Pizza Place',
            cuisines: ['Italian'],
            priceRange: 2,
            location: {
                neighborhood: 'Gold Coast',
                latitude: 41.897,
                longitude: -87.623,
                address: {
                    address1: '101 Pine St',
                    city: 'Chicago',
                },
            },
            highlight: 'Wood-fired pizzas and more',
            acceptedCards: ['Visa', 'MasterCard'],
            hasDelivery: true,
            hasTakeOut: true,
        },
        {
            id: 5,
            rating: {
                overallRating: 4.1,
                numberOfRatings: 35,
                numberOfReviews: 10,
            },
            benefits: [
                {
                    value: 7,
                    dayOfWeek: 'Sat',
                    monthDay: '23/07/2023',
                },
            ],
            type: 'cafe',
            imageBaseUrl: 'https://cdn.pixabay.com/photo',
            logoImage: '/2013/08/11/19/46/coffee-171653_1280.jpg',
            photos: [
                '/2013/08/11/19/46/coffee-171653_1280.jpg',
                '/2020/06/08/16/49/pizza-5275191_960_720.jpg',
            ],
            menu: {
                menuImages: ['/2016/11/29/13/02/cheese-1869708_960_720.jpg'],
                externalUrl: 'http://www.bestcafe.com/menu',
            },
            name: 'The Coffee Spot',
            cuisines: ['Coffee', 'Snacks'],
            priceRange: 1,
            location: {
                neighborhood: 'Lincoln Park',
                latitude: 41.9214,
                longitude: -87.6513,
                address: {
                    address1: '321 Green St',
                    city: 'Chicago',
                },
            },
            highlight: 'Cozy cafe with great coffee',
            acceptedCards: ['Visa', 'MasterCard'],
            hasDelivery: false,
            hasTakeOut: true,
        },
        {
            id: 6,
            rating: {
                overallRating: 4.7,
                numberOfRatings: 150,
                numberOfReviews: 45,
            },
            benefits: [
                {
                    value: 10,
                    dayOfWeek: 'Sun',
                    monthDay: '24/07/2023',
                },
            ],
            type: 'bakery',
            imageBaseUrl: 'https://cdn.pixabay.com/photo',
            logoImage: '/2016/11/29/10/09/bakery-1868925_1280.jpg',
            photos: [
                '/2024/01/27/10/24/bread-8535650_960_720.jpg',
                '/2020/06/12/13/56/flour-5290444_960_720.jpg',
            ],
            menu: {
                menuImages: ['/2020/08/07/12/27/bread-5470532_960_720.jpg'],
                externalUrl: 'http://www.bakeryshop.com/menu',
            },
            name: 'The Bakery Shop',
            cuisines: ['Bakery', 'Pastries'],
            priceRange: 2,
            location: {
                neighborhood: 'West Loop',
                latitude: 41.8825,
                longitude: -87.6441,
                address: {
                    address1: '678 Baking Ave',
                    city: 'Chicago',
                },
            },
            highlight: 'Freshly baked bread and pastries daily',
            acceptedCards: ['Visa', 'MasterCard', 'Amex'],
            hasDelivery: true,
            hasTakeOut: true,
        },
        {
            id: 7,
            rating: {
                overallRating: 4.3,
                numberOfRatings: 90,
                numberOfReviews: 30,
            },
            benefits: [
                {
                    value: 8,
                    dayOfWeek: 'Tue',
                    monthDay: '25/07/2023',
                },
            ],
            type: 'barbecue',
            imageBaseUrl: 'https://cdn.pixabay.com/photo',
            logoImage: undefined, // '/2015/06/15/20/20/bbq-810545_1280.jpg',
            photos: undefined,
            // [
            //     '/2015/06/15/20/20/bbq-810545_1280.jpg',
            //     '/2023/12/13/17/54/bun-8447394_960_720.jpg',
            // ]
            menu: {
                menuImages: ['/2017/05/15/01/18/baked-2313462_960_720.jpg'],
                externalUrl: 'http://www.bbqjoint.com/menu',
            },
            name: 'BBQ Joint',
            cuisines: ['Barbecue'],
            priceRange: 3,
            location: {
                neighborhood: 'Old Town',
                latitude: 41.9075,
                longitude: -87.6386,
                address: {
                    address1: '543 Smoky Ln',
                    city: 'Chicago',
                },
            },
            highlight: 'Award-winning smoked meats',
            acceptedCards: ['Visa', 'MasterCard'],
            hasDelivery: false,
            hasTakeOut: true,
        },
        {
            id: 8,
            rating: {
                overallRating: 4.9,
                numberOfRatings: 200,
                numberOfReviews: 60,
            },
            benefits: [
                {
                    value: 15,
                    dayOfWeek: 'Wed',
                    monthDay: '26/07/2023',
                },
            ],
            type: 'steakhouse',
            imageBaseUrl: 'https://cdn.pixabay.com/photo',
            logoImage: '/2018/08/29/19/03/steak-3640560_1280.jpg',
            photos: [
                '/2018/08/29/19/03/steak-3640560_1280.jpg',
                '/2021/05/01/22/01/meat-6222139_960_720.jpg',
            ],
            menu: {
                menuImages: ['/2019/07/16/20/07/steak-4342500_960_720.jpg'],
                externalUrl: 'http://www.steakhouseprime.com/menu',
            },
            name: 'Steakhouse Prime',
            cuisines: ['Steakhouse'],
            priceRange: 4,
            location: {
                neighborhood: 'River North',
                latitude: 41.8902,
                longitude: -87.6262,
                address: {
                    address1: '876 Carnivore St',
                    city: 'Chicago',
                },
            },
            highlight: 'Premium steaks and fine dining',
            acceptedCards: ['Visa', 'MasterCard', 'Amex'],
            hasDelivery: false,
            hasTakeOut: false,
        },
        {
            id: 9,
            rating: {
                overallRating: 4.4,
                numberOfRatings: 110,
                numberOfReviews: 35,
            },
            benefits: [
                {
                    value: 9,
                    dayOfWeek: 'Fri',
                    monthDay: '28/07/2023',
                },
            ],
            type: 'seafood',
            imageBaseUrl: 'https://cdn.pixabay.com/photo',
            logoImage: '/2021/07/23/09/32/bread-6486963_1280.jpg',
            photos: [
                '/2020/12/11/03/51/dessert-5821863_960_720.jpg',
                '/2016/11/29/09/00/doughnuts-1868573_960_720.jpg',
            ],
            menu: {
                menuImages: ['2018/08/29/19/03/steak-3640560_1280.jpg'],
                externalUrl: 'http://www.oceanicdelights.com/menu',
            },
            name: 'Oceanic Delights',
            cuisines: ['Seafood'],
            priceRange: 3,
            location: {
                neighborhood: 'Streeterville',
                latitude: 41.8927,
                longitude: -87.6209,
                address: {
                    address1: '321 Ocean Blvd',
                    city: 'Chicago',
                },
            },
            highlight: 'Fresh seafood from the Atlantic',
            acceptedCards: ['Visa', 'MasterCard'],
            hasDelivery: true,
            hasTakeOut: false,
        },
        {
            id: 10,
            rating: {
                overallRating: 4.0,
                numberOfRatings: 70,
                numberOfReviews: 25,
            },
            benefits: [
                {
                    value: 6,
                    dayOfWeek: 'Sat',
                    monthDay: '29/07/2023',
                },
            ],
            type: 'diner',
            imageBaseUrl: 'https://cdn.pixabay.com/photo',
            logoImage: '/2021/11/10/18/09/restaurant-6784529_1280.jpg',
            photos: [
                '/2016/03/05/09/22/eat-1237431_960_720.jpg',
                '/2023/03/21/16/55/beef-7867701_960_720.jpg',
            ],
            menu: {
                menuImages: ['/2018/08/30/14/46/food-3642376_960_720.jpg'],
                externalUrl: 'http://www.americandiner.com/menu',
            },
            name: 'Classic American Diner',
            cuisines: ['American', 'Breakfast'],
            priceRange: 1,
            location: {
                neighborhood: 'The Loop',
                latitude: 41.8837,
                longitude: -87.6277,
                address: {
                    address1: '987 Breakfast St',
                    city: 'Chicago',
                },
            },
            highlight: 'All-day breakfast and classic dishes',
            acceptedCards: ['Visa', 'MasterCard'],
            hasDelivery: false,
            hasTakeOut: true,
        },
    ],
};

export const mockResponseByMerchantId: MerchantDto[] = mockResponse.merchants.map((merchant) => ({
    id: merchant.id,
    rating: merchant.rating || {
        description: '',
        overallRating: 0,
        numberOfRatings: 0,
        numberOfReviews: 0,
    },
    description:
        merchant.id === 1
            ? "Indulge in the soulful flavors of Mexico at Blanquita's Mexican Restaurant, where each dish is a celebration of family recipes passed down through generations"
            : "Indulge in the soulful flavors of Mexico at Blanquita's Mexican Restaurant, where each dish is a celebration of family recipes passed down through generations, served with warmth and hospitality. With its vibrant ambiance and mouthwatering selection of enchiladas, tacos, and homemade salsas, Blanquita's invites diners to savor the essence of Mexican cuisine in every bite.",
    benefits: merchant.benefits || [],
    type: merchant.type || 'restaurant',
    hours: [
        {
            dayOfWeek: 'Monday',
            dayHours: [
                {
                    open: '11:30AM',
                    close: '10:00PM',
                },
            ],
            openForBusiness: true,
        },
        {
            dayOfWeek: 'Tuesday',
            dayHours: [
                {
                    open: '11:30AM',
                    close: '10:00PM',
                },
            ],
            openForBusiness: true,
        },
        {
            dayOfWeek: 'Wednesday',
            dayHours: [
                {
                    open: '11:30AM',
                    close: '10:00PM',
                },
            ],
            openForBusiness: false,
        },
        {
            dayOfWeek: 'Thursday',
            dayHours: [
                {
                    open: '11:30AM',
                    close: '10:00PM',
                },
            ],
            openForBusiness: true,
        },
        {
            dayOfWeek: 'Friday',
            dayHours: [
                {
                    open: '11:30AM',
                    close: '10:00PM',
                },
            ],
            openForBusiness: true,
        },
        {
            dayOfWeek: 'Saturday',
            dayHours: [
                {
                    open: '11:30AM',
                    close: '10:00PM',
                },
            ],
            openForBusiness: true,
        },
        {
            dayOfWeek: 'Sunday',
            dayHours: [
                {
                    open: '11:30AM',
                    close: '10:00PM',
                },
            ],
            openForBusiness: false,
        },
    ],
    imageBaseUrl: merchant.imageBaseUrl || '',
    logoImage: merchant.logoImage || '',
    photos: merchant.photos || [],
    menu: merchant.menu || {
        menuImages: [],
        externalUrl: '',
    },
    name: merchant.name,
    cuisines: merchant.cuisines || [],
    priceRange: merchant.priceRange || 0,
    location: {
        neighborhood: merchant.location.neighborhood,
        latitude: merchant.location.latitude,
        longitude: merchant.location.longitude,
        address: {
            address1: merchant.location.address.address1,
            address2: '',
            city: merchant.location.address.city,
            state: 'IL',
            zip: '60644',
        },
    },
    phone: '(708) 300-0008',
    websiteUrl: `https://www.${merchant.name}.com`,
    facebookUrl: '',
    twitterUrl: '',
    highlight: merchant.highlight || '',
    features: [
        {
            category: 'Open For',
            types: ['Lunch', 'Dinner'],
        },
    ],
    acceptedCards: merchant.acceptedCards || [],
    monthlyLimits: {
        description: 'Monthly limits are set by calendar month',
        maximumSpend: 500,
        maximumVisits: 10,
        benefitType: 'Unlimited',
    },
}));
